<template>
    <a href="/" style="text-decoration: none; color: var(--ion-color-dark);margin: auto">
        <h1 class="title">
            OPEN<strong>CIDER </strong>
            <span><img style="width: 23px; height: 23px;" src="assets/icon/icon.png" /></span>
        </h1>
    </a>
</template>

<style>
.title {
    padding-left: 0em;
    margin: auto;
}
    
@media screen and (max-width: 600px) {
    .title {
        padding-left: 0%;
    }
}
</style>